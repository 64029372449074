

<template>
    <svg
      class="svg-shape"
      width="70%"
      height="70%"
      viewBox="
      0 0 100 100"     
    > 
    
    <!-- <linearGradient id="grad1" x1="0%" x2="100%" y1="0%" y2="0%">
      <stop offset="0%" stop-color="yellow" />
      <stop offset="100%" stop-color="red" />
    </linearGradient>
   -->
      <path        
        d="M 93.7 41 L 93.7 3 L 62.5 3 L 54.7 10 L 63.8 10 L 40.4 15 L 31.3 20 L 17 29 L 0.1 29 L 0.1 32 L 45.6 32 L 40.4 41 L 93.7 41"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: "ShapeSvg3",
  };
  </script>
  
  <style scoped>
  /* .icon {
    display: block;
  } */
  .svg-shape {
  fill: rgb(208, 146, 246);
  stroke: rgb(255, 255, 255);
  stroke-opacity: 1;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;    
  filter: drop-shadow(3px 5px 6px rgb(0 0 0 / 0.5));
  }
  </style>
  

