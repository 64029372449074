<script>
import '@fortawesome/fontawesome-free/js/all'
import HeaderComp from "@/components/HeaderComp.vue";
import SideBar from "@/components/sidebar/SideBar";
import FooterComp from "@/components/FooterComp.vue";
import { sidebarWidth } from "@/components/sidebar/state";
export default {
  components: { HeaderComp, SideBar, FooterComp },
  setup() {
    return { sidebarWidth };
  },
};
</script>
<template>
  <div>
    <HeaderComp />
    <SideBar />
    <FooterComp />
    <!-- <div :style="{ 'margin-left': sidebarWidth }">
      <router-view />
    </div> -->
    
      <router-view />
    
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* background-image: url("./assets/bg-piano3.jpg"); */
  background-color: white;
  height: 100vh;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
