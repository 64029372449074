<script>
import SideBarLink from "./SideBarLink";
import { collapsed, toggleSidebar, sidebarWidth } from "./state";
import PianoLeftIcon from "../iconsSVG/PianoLeftIcon.vue";
import PianoRightIcon from "../iconsSVG/PianoRightIcon.vue";


export default {
  props: {},
  components: { SideBarLink, PianoLeftIcon, PianoRightIcon },
  setup() {
    return { collapsed, toggleSidebar, sidebarWidth };
  },
};
</script>

<template>
  <div class="sidebar" :style="{ width: sidebarWidth }">
    
      <span
        class="collapse-icon-upper"
        :class="{ 'rotate-180': collapsed }"
        @click="toggleSidebar"
      >
        <i class="fas fa-angle-double-left" />
      </span>
    <br>
    <h1>
      <span v-if="collapsed">
        
        <div><PianoRightIcon style="color: white" /></div>
        <div><PianoLeftIcon style="color: white" /></div>
        <!-- <div>D</div> -->
      </span>
      <span v-else>Das Duo</span>
    </h1>

    <SideBarLink to="/" icon="fas fa-home">Home</SideBarLink>
    <!-- <SideBarLink to="/dashboard" icon="fas fa-columns">Dashboard</SideBarLink> -->
    <SideBarLink to="/konzerteview" icon="fa solid fa-music">Konzerte</SideBarLink>
    <SideBarLink to="/repertoireview" icon="fa-solid fa-compact-disc">Repertoire</SideBarLink>
    <SideBarLink to="/galerieview" icon="fa-solid fa-icons">Galerie</SideBarLink>
    <SideBarLink to="/aboutview" icon="fa-solid fa-volume-high">About</SideBarLink>
    <SideBarLink to="/kontaktview" icon="fa-solid fa-microphone-lines">Kontakt</SideBarLink>

    <span
      class="collapse-icon"
      :class="{ 'rotate-180': collapsed }"
      @click="toggleSidebar"
    >
      <i class="fas fa-angle-double-left" />
    </span>
  </div>
</template>

<style>
:root {
  /* --sidebar-bg-color: goldenrod;
  --sidebar-item-hover: rgb(235, 189, 72);
  --sidebar-item-active: rgb(149, 149, 149); */
  --sidebar-bg-color: rgb(255, 255, 255);
  --sidebar-item-hover: rgb(198, 198, 198);
  --sidebar-item-active: rgb(149, 149, 149);
}
</style>

<style scoped>
.sidebar {  
  color: rgb(195, 195, 195);
  background-color: var(--sidebar-bg-color);

  float: left;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5em;

  transition: 0.3s ease;

  display: flex;
  flex-direction: column;
  box-shadow: 5px 10px 20px rgba(23, 0, 113, 0.451);
}

.sidebar h1 {
  height: 2.5em;
}
.collapse-icon-upper {
  position: absolute;
  top: 0;
  padding: 0.75em;
  color: rgba(96, 96, 96, 0.7);
  transition: 0.2s linear;
}

.collapse-icon {
  position: absolute;
  bottom: 0;
  padding: 0.75em;

  color: rgba(66, 66, 66, 0.7);

  transition: 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
</style>
