<template>
  <div class="about">
    <h1>About page</h1>    
  </div>
</template>



<style scoped>
.about {
  margin: 4em;
  color: rgb(0, 0, 0);
}
</style>

