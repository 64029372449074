<template>
  <svg
    class="svg-shape"
    width="90%"
    height="90%"
    viewBox="
      0 0 100 100"    
  >
    <path
      d="M 2 21 L 1.3 40 L 32.5 40 L 41 34 L 99 34 L 105 64 L 198 25 L 105 26 L 104 4 L 77 4 L 77 14 L 67 14 L 57 5 L 42 5 L 59 21 L 2 21"
    />
  </svg>
</template>

<script>
export default {
  name: "ShapeSvg2",
};
</script>

<style scoped>
.svg-shape {
  fill: rgb(251, 236, 173);
  stroke-opacity: "0";
  stroke-width: "0";
  stroke: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  filter: drop-shadow(3px 3px 6px rgb(0 0 0 / 0.5));
}
</style>
