<script>
// import PianoShape1 from "./iconsSVG/PianoShape.vue";
// import PianoShape2 from "./iconsSVG/PianoShape.vue";
import ShapeSvg1 from "./svgShapes/ShapeSvg1.vue";
import ShapeSvg2 from "./svgShapes/ShapeSvg2.vue";
import ShapeSvg3 from "./svgShapes/ShapeSvg3.vue";
export default {
  components: { ShapeSvg1, ShapeSvg2, ShapeSvg3 },
  name: "HeaderComp",
};
</script>
<template>
  <div class="main-view-header">
    <!-- <div class="piano-shape1">
      <PianoShape1 />
    </div> -->
    <!-- <div class="piano-shape2">
      <PianoShape2 />
    </div> -->
    <div class="img-piano">        
        <img src="../assets/PianoBlack.png" />
      </div>
    <div class="shape-svg-1">
      <ShapeSvg1 />
    </div>
    <div class="shape-svg-2">
      <ShapeSvg2 />
    </div>
    <div class="shape-svg-3">
      <ShapeSvg3 />
    </div>
    <div class="header-title">
      <span>DAS DUO </span>
      <span>"Piano Moments"</span>      
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-view-header {
  display: flex;
  background-color: rgb(236, 255, 254);
  min-height: 25vh;
}
.header-title {
  z-index: 100;
  max-height: 70px;
  margin-left: 50px;
  margin-top: 7px;
  margin-right: 7px;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 0px;
  padding-right: 40px;
  font-size: xx-large;
  color: white;
  font-weight: 900;  
  background-color: rgba(49, 56, 255, 0.678);
  box-shadow: 5px 5px 5px rgba(128, 128, 128, 0.559);
}
.img-piano {
  z-index: 100;
display: flex;
position: absolute;
top: 10px;
left: 50%;
}
.piano-shape1 {
  width: 200px;
  z-index: 20;
  margin: 0;
  margin-left: 30px;
  position: absolute;
  top: 20%;
  left: 80%;
  transform: translate(-50%, -50%);
}
.piano-shape2 {
  z-index: 20;
  margin: 0;
  margin-left: 20px;
  position: absolute;
  top: 0%;
  left: 60%;
  transform: translate(-50%, -50%);
}
.shape-svg-1 {
  width: 70vw;
  z-index: 2;
  margin: 0;
  position: absolute;
  top: 60px;
  left: 0%;
  transform: translate(-10%, 0%);
}
.shape-svg-2 {
  width: 60vw;
  z-index: 1;
  margin: 0;
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(0%, 0%);
}
.shape-svg-3 {  
  width: 60%;
  z-index: 3;
  margin: 0;
  position: absolute;
  top: 0%;
  right: 0%;
  transform: translate(15%, 0%);
  
}
</style>
