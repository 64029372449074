<script>
// import IconTwoNotes from "./iconsSVG/IconTwoNotes.vue";
// import IconOneNote from "./iconsSVG/IconOneNote.vue";

export default {
  components: { },
  // components: { IconTwoNotes, IconOneNote },
  setup() {},
};
</script>

<template>
  <div class="footer">
    <div class="bottom-container-left">
      <!-- <IconOneNote class="bottom-left-square" style="color: white" />
      <IconTwoNotes class="bottom-left-square" style="color: white" />
      <IconOneNote class="bottom-left-square" style="color: white" /> -->
    </div>

    <div class="bottom-container-right">
      <p>
        DasDuo <br />Tel.-Nr.: 0162/9442015 <br />9-14 und ab 19:30 Uhr
        <br />Email: pianomoments@web.de
      </p>
    </div>
  </div>
</template>

<style scoped>
.footer {
  z-index: 101;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-top: 1px solid rgb(148, 148, 148);
  color: rgb(42, 42, 42);
  background-color: rgb(255, 255, 255);
  box-shadow: -25px 0px 20px rgba(23, 0, 113, 0.451);
  min-height: 10vh;
  max-height: 40vh;
  min-width: 100%;
}

.bottom-container-left {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-left: 55px;
  max-width: 40vw;
}
.bottom-left-square {
  width: 30px;
  height: 30px;
  margin: 5px;
  padding: 10px;
  box-shadow: 1px 1px 15px rgb(255, 255, 255, 0.3);
}
.bottom-container-right {
  margin: 5px;
  padding: 10px;
  text-align: end;
  min-width: 45vw;
}
</style>
