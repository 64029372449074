<template>
    <div class="galerie">
      <h1>Galerie</h1>
    </div>
  </template>

  
<style scoped>
.galerie {
  margin: 1em;
  color: rgb(0, 0, 0);
  min-height: 90vh;
}
</style>
