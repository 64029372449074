<template>
  <svg class="svg-shape" width="80%" height="80%" viewBox="0 0 100 100">
    <path
      d="M 1.3 2 L 1.3 40 L 32.5 40 L 40.3 33 L 31.2 33 L 54.6 28 L 63.7 23 L 78 14 L 94.9 14 L 94.9 11 L 49.4 11 L 54.6 2 L 1.3 2"            
    />
  </svg>
</template>

<script>
export default {
  name: "ShapeSvg1",
};
</script>

<style scoped>
.svg-shape {
  fill: rgb(255, 145, 200);
  stroke-opacity: "0.5";
  stroke-width: "0";
  stroke: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  filter: drop-shadow(8px 8px 6px rgb(0 0 0 / 0.5));
}
</style>
