<script>
export default {  
  name: "MainView",
  props: {
    msg: String,
  },
  
};
</script>

<template>
  <div class="main-view-body">
    <h1 class="main-view-header">Willkommen</h1>

    <div class="main-view-text">
      <div class="img-main">        
        <!-- <img src="../assets/duopianoDarkS.png" /> -->
      </div>

      <p>
        Planen Sie ein kleines Event bei sich zu Hause ider eine Betriebsfeier
        in der Firma? Einen Kindergeburtstag, Jubiläum, Einwelhung oder eine
        Weihnachtsfeier? Oder adnere events? Und haben Sie ein Klavier oder E
        Piano stehen, das wir zum Klingen bringen können?
        <br />
        Mit ausgesuchten und abgestimmten 2- und 4-händigen Musikstücken machen
        wir Ihr Event zu etwas Besonderem!
        <br />
        Wir bieten Musikstücke an aus Filmen wie "Die Eiskönigin", "Mascha und
        der Bär", "Pink Panther", "James Bond", "Star Wars", "Die fabelhafte
        Welt der Amelie", "Der Pate", "The Professional"...
        <br />
        Ebenso klassische und moderne Stücke von Bach und Beethoven bis Billie
        Ellish und Adele und vie mehr...
        <br />
        Sie erreichen uns über:<br />
        <br />Tel.-Nr.: 0162/9442015<br />
        <br />9-14 und ab 19:30 Uhr<br />
        Email: pianomoments@web.de
      </p>
    </div>
  </div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-view-body {
  z-index: 301;
  display: block;
  margin-left: 60px;
  margin-right: 15px;
  padding-bottom: 15vh;
  background-color: rgba(0, 0, 0, 0);
}
.main-view-header {
  z-index: 302;
  font-size: xx-large;
  color: rgb(41, 41, 41);
  text-shadow: 5px 5px 5px #00000050;
  margin-top: 12vh;
}
@media only screen and (max-width: 400px) {
  .main-view-text {
    z-index: 301;
    display: flex;
    flex-wrap: wrap;
    padding-top: 5vh;
  }
}
.main-view-text {
  z-index: 100;
  display: flex;
  padding: 1em;
}

.img-main img {
  float: left;
  margin: 10px;
}

p {
  z-index: 100;
  text-align: center;
  font-size: large;
  color: rgb(0, 0, 0);
}

</style>
