<template>
    <div class="konzerte">
      <h1>Konzerte</h1>
    </div>
  </template>
  
  
<style scoped>
.konzerte {
  margin: 1em;
  color: rgb(0, 0, 0);
  min-height: 90vh;
}
</style>
