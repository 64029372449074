<template>
    <svg
      class="icon"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      stroke-width="2"
      stroke="grey"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path
        d="m 8.64 28.08 l 6.24 0 c 10.92 0 9.36 -3.12 9.36 -6.24 c 0 -10.92 -9.8686 -6.8125 -10.92 -15.6 c -0.1357 -1.7332 -3.12 -1.56 -4.68 -1.56 l 0 0 l 0 0 l 0 23.4 m 0.0012 0.0012 l -3.4308 0.0012 l -0.066 -23.3928 L 8.6376 4.6788 l 0.0048 0.3408 l -3.1716 0.0168 l 0.2184 22.4988 l 2.9724 0.012"
        stroke-width="1"       
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: "PianoLeftIcon",
  };
  </script>
  
  <style scoped>
  .icon {
    display: block;
  }
  </style>
  

